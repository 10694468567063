(() => {
  const tlItems = document.querySelectorAll('.timeline-item');
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('scrolled');
        entry.target.classList.add('colored');
      } else {
        entry.target.classList.remove('colored');
      }
    });
  }, {
    threshold: 0.25,
    rootMargin: '-20% 0% -20% 0%',
    // rootMargin: `-${window.innerHeight / 2}px 0px -${window.innerHeight / 3}px 0px`,
  });
  tlItems.forEach((entry) => {
    observer.observe(entry);
  });
})();
