import jQuery from 'jquery';
import smoothscroll from 'smoothscroll-polyfill';
import GLightbox from 'glightbox';
import LazyLoad from 'vanilla-lazyload';

(($) => {
  smoothscroll.polyfill();
  const lazyLoad = new LazyLoad();
  lazyLoad.update();
  $('.hero-scroll').on('click', () => {
    window.scrollTo({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth',
    });
  });
  const bodyObserver = new IntersectionObserver((cb) => {
    if (cb[0].isIntersecting) {
      document.body.classList.remove('entered');
    } else {
      document.body.classList.add('entered');
    }
  });
  bodyObserver.observe(document.querySelector('.body-observer'));

  /** *****************************************
   * mobile nav
   ******************************************* */
  const $body = $('body');
  const $burger = $('.nav-icon-toggle');
  $burger.on('click', () => {
    $body.toggleClass('open-mobile-nav');
  });

  /** *****************************************
   * accordions
   ******************************************* */
  $('.accordion-title').on('click', function(ev) { // eslint-disable-line
    const $this = $(this);
    $this.toggleClass('open');
    $this.next().slideToggle();
  });

  /** *****************************************
   * light box
   ******************************************* */
  // eslint-disable-next-line
  const lightbox = GLightbox();
  $('.video video').on('click', (ev) => {
    const vidBox = GLightbox({
      elements: [
        {
          type: 'video',
          source: 'local',
          href: ev.currentTarget.getAttribute('src'),
        },
      ],
    });
    vidBox.open();
  });

  $('.gallery-image').on('click', (ev) => {
    const gallery = window.LB_GALLERY;
    gallery.startAt = +ev.currentTarget.dataset.imgNr;
    const glBox = GLightbox(gallery);
    glBox.open();
  });
})(jQuery);
