(() => {
  /** *****************************************
   * animation home lead
   ******************************************* */
  const homeLead = document.querySelector('.home-lead');
  if (homeLead) {
    const observer = new IntersectionObserver((entries, observer) => { // eslint-disable-line
      if (entries[0].isIntersecting) {
        entries[0].target.classList.add('scrolled');
        observer.unobserve(entries[0].target);
      }
    }, { threshold: 1 });
    observer.observe(homeLead);
  }

  /** *****************************************
   * animation home project list
   ******************************************* */
  const projects = document.querySelectorAll('.project-list > div');

  const passedProjectObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.remove('passed');
      } else {
        entry.target.classList.add('passed');
      }
    });
  }, { threshold: 0.5 });

  const projectsObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('scrolled');
        observer.unobserve(entry.target);
        passedProjectObserver.observe(entry.target);
      }
    });
  }, {
    rootMargin: `0px 0px -${window.innerHeight / 2}px 0px`,
  });
  projects.forEach((entry) => {
    projectsObserver.observe(entry);
  });
})();
