(() => {
  const $grantProcess = document.querySelector('.grant-process-animation');
  let $processSteps = null;
  let $nextSteps = null;

  function animateProcessStep(step, timeout) {
    window.setTimeout(() => {
      $processSteps[step].classList.add('animate');
    }, timeout);
  }

  function animateNextStep(step, timeout) {
    window.setTimeout(() => {
      $nextSteps[step].classList.add('animate');
    }, timeout);
  }

  function groupAnimation(group) {
    // eslint-disable-next-line default-case
    switch (group) {
      case 1:
        animateProcessStep(0, 0);
        animateNextStep(0, 1000);
        animateProcessStep(1, 1500);
        animateNextStep(1, 2500);
        animateProcessStep(2, 3000);
        window.setTimeout(() => {
          document.querySelector('.process-divider').classList.add('animate');
        }, 4000);
        break;
      case 2:
        document.querySelector('.process-heading').classList.add('animate');
        animateProcessStep(3, 1000);
        animateNextStep(2, 1000);
        break;
      case 3:
        animateProcessStep(4, 0);
        animateNextStep(3, 1000);
        animateProcessStep(5, 1500);
        break;
      case 4:
        document.querySelector('.grant-app-laptop').classList.add('animate');
    }
  }

  if ($grantProcess) {
    const $animGroups = $grantProcess.querySelectorAll('.animation-group');
    const grantProcessObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const { group } = entry.target.dataset;
          groupAnimation(+group);
          observer.unobserve(entry.target);
        }
      });
    }, {
      rootMargin: `0px 0px -${window.innerHeight / 2}px 0px`,
    });
    $animGroups.forEach((group) => {
      grantProcessObserver.observe(group);
    });

    $processSteps = document.querySelectorAll('.process-step');
    $nextSteps = document.querySelectorAll('.next-step');

    const $circles = document.querySelector('[data-process-step="1"] svg');
    const circleOuterWidth = $circles.querySelector('.outer').getTotalLength();
    const circleInnerWidth = $circles.querySelector('.inner').getTotalLength();

    const style = document.createElement('style');
    style.innerHTML = `
    :root {
      --circle-outer-width: ${circleOuterWidth};
      --circle-inner-width: ${circleInnerWidth};
    }
  `;
    document.head.appendChild(style);
  }
})();
